.tooltip-icon {
    margin-left: 8px;
    margin-right: 0px;
    padding: 0;
    border: none;
  }

.amplify-switch-label {
  padding: 0;
}

.amplify-switch-track--disabled{
  cursor: not-allowed;
}

.amplify-switch-track{
  background-color: #5C6670;
}