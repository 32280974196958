.unsupported-browser-container {
  width: 100vw;
  height: 100vh;
}
.info-message-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 200px auto;
  width: 448px;
  text-align: center;
}

.browser-list {
  text-align: left;
}

.info-heading {
  font-weight: 600;
}
