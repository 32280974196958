.homepage-wrapper {
  padding: 0;
  margin: 0;
  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  .alert-component {
    margin: 0;
  }

  .amplify-heading {
    text-align: center;
    font-size: 39px;
    padding: 0 50px;
  }

  .oktaUnverified {
    .amplify-button {
      width: 24px;
      height: 24px;
    }
    .amplify-alert__dismiss {
      align-self: flex-start;
      border: none;
      margin-top: 0px;

      .amplify-icon {
        content: url(../../assets/icon-x.svg);
        transform: scale(1);
      }
    }
  }
}

.homepage-btn-group {
  width: 100%;
}

.homepage-btn-group-with-alert {
  margin: 20px 0 25px;
}

.homepage-content {
  margin: 0 auto;
  padding: 0;
  .amplify-text {
    text-align: center;
  }

  @media (max-width: 448px) {
    padding: 0px var(--amplify-space-large);
  }
}

.homepage-left-content {
  // padding: 1.5rem 0rem;

  @media (max-width: 448px) {
    padding: 2rem 1rem var(--amplify-space-large);
  }
}

.abs-logo {
  display: block;
  margin: 16px auto;
}

.abs-logo-with-alert {
  display: block;
  margin: 0 auto 20px;
}

.homepage-footer-with-alert {
  margin: 20px 0;
}

.homepage-login-btn {
  border-radius: 8px;
  height: 48px;
}

.login-btn {
  background-color: white;
  color: #0f487c;
  border: none;
  font-weight: 600;
}

.signup-btn {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

@media (prefers-contrast: more) {
  .homepage-content path {
    fill: currentColor;
    stroke: none;
  }
}

.hero-image-view {
  @media (max-width: 767px) {
    display: none;
  }
}
