.insight-select-parent {
    border: 2px solid #5C6670;
    border-radius: 8px;
    
    .insight-select__control {
        border-radius: 8px;
        border: none;
    }

    .insight-select__indicator-separator {
        display:none;
    }

    .insight-select__indicator {
        color: #0D1926;
    }
}