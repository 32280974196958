.container {
  width: 100vw;
  height: 85vh;
}

.content {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.modal {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 42px;
}

.modal img {
  width: 120px;
  height: 106px;
}

.modal h1 {
  font-size: 36px;
  font-weight: 600;
  line-height: 160%; /* 57.6px */
  letter-spacing: 0.15px;
  margin-top: 0;
  padding: 8px;
}

.modal p {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: -0.56px;
  margin-top: 0;
}

.modal button {
  width: 174px;
  height: 46px;
}
