.notification-heading {
  font-family: 'Open Sans';
  font-size: 19px;
  font-weight: 600;
  color: #326297;
}

.error-text {
  color: #DC2626;
}

.notification-text {
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 400;
}

.notification-close-icon {
  align-self: flex-start;
  margin-left: auto;
  min-width: 24px;
}

.notification-close-icon:hover {
  cursor: pointer;
}