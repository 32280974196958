.feedback-header {
  font-size: 19px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #326297;
}

.css-7qmtgc-MuiRating-icon {
  margin-right: 10px;
}

.css-1c99szj-MuiRating-icon {
  margin-right: 10px;
}

.feedback-label {
  font-size: 12px;
  margin: 0;
  height: 20px;
  color: #326297;
  background-color: #f3f7fb;
  width: fit-content;
  border-radius: 500px;
  padding: 10px;
  line-height: 0;
  text-align: center;
}

.modal-heading {
  font-size: 33px;
  font-weight: 600;
  color: #326297;
  margin: 0;
}

.asp-feedback-modal .tooltip-component {
  margin-bottom: 10px;
}

.comments-text {
  font-size: 19px;
  font-weight: 600;
  margin: 44px 0 36px;
}

.report-button-group-wrapper {
  flex-wrap: wrap;
}

.MuiSnackbarContent-message {
  width: 100%;
}

.inline-error-feedback {
  align-self: flex-end;
}

.MuiRating-iconFilled {
  color: var(--amplify-colors-brand-primary-60);
}

.MuiRating-iconHover {
  color: var(--amplify-colors-brand-primary-80);
  margin-right: 10px;
}

.MuiRating-iconActive {
  color: var(--amplify-colors-brand-primary-90);
  margin: 0px;
}

.MuiRating-pristine {
  display: none;
}
