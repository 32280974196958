.manage-connections-page-main-wrapper {
  padding: 60px 0px;
}

.manage-connections-page-main-heading {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  color: #0d1926;
  padding-bottom: 3rem;
}

@media screen and (max-width: 767px) {
  .manage-connections-page-main-heading {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: left;
  }
  .add-business-button-manage-connections {
    min-width: 100%;
  }
  .manage-connections-page-main-wrapper {
    padding: 20px 0px;
  }
}
