.side-navbar {
  padding: 0;
  margin: 0;
  border-style: solid none;
  border-color: #DCDEE0;
  border-width: 1px;
}

.side-navBar-item {
  display: block;
  position: relative;
  // background: #fff;
  &:not(:last-child)::after {
    content: ' ';
    position: absolute;
    display: block;
    top: 25px;
    left: 15px;
    height: 100%;
    border: 1px solid #71767d;
    border-radius: 2px;
  }
}

.side-navbar-expander {
  .amplify-expander__trigger {
    padding: 0;
  }

  .amplify-expander__content__text {
    padding: 0;
  }

  .amplify-expander__item:first-of-type {
    border-radius: 0;
  }

  .amplify-expander__icon {
    display: none;
  }
}

.navbar-link {
  color: #71767d;
  display: inline;
  margin-left: 4px;
  word-break: break-word;
  text-decoration: none;
  padding: 16px 0px 16px 8px;
  width: calc(100% - 39px);

  &:hover {
    text-decoration-line: underline;
  }
  &:focus {
    box-shadow: 0 0 0 2px #9263de;
    outline: 1px solid transparent;
  }
}

.processing:before {
  background-color: white;
  border: 2px solid #326297;
}

.processing .navbar-link {
  color: black;
  font-weight: 600;
}

.completed:before {
  background-color: #326297;
  border: 2px solid #326297;
}

.completed .navbar-link {
  color: #000000;
}
