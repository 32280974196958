.confirmation-wrapper {
  margin: 0;
}

.submission-receipt-card {
  display: flex;
  flex-direction: column;
}

.confirmation-tick-icon {
  height: 48px;
  width: 48px;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.1));
}

.confirmation-heading {
  font-family: Open Sans;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
}

.submission-logo-heading {
  padding: 0px;
  margin: 0px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
}
.submission-flex {
  font-weight: 600;
  padding-bottom: 12px;
  border-bottom: 1px solid #eff0f0;
}

.submission-info-header {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  border-bottom: 1px solid #eff0f0;
  margin-top: 24px;
  padding-bottom: 24px;
  line-height: 32px;
}

.submission-info-value {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.submission-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.submission-title-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  font-variation-settings: 'wdth' 100;
}

.download-btn {
  font-size: 16px;
  font-weight: 600;
  color: #326297;
  height: 33px;
  min-width: 219px;
  border: 2px solid #878889;
  border-radius: 8px;
}
