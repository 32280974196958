.highcharts-no-data {
  span {
    white-space: normal !important;
    text-align: center !important;
    min-width: 420px !important;
  }
}

.amplify-text {
  max-width: 768px;
}