.report-button-group-wrapper {
  margin-top: 30px;
}

.report-button-group-button {
  min-width: 176px;
  border-radius: 8px;
  font-weight: 600;
  gap: 8px;
  border: 2px solid var(--amplify-colors-brand-primary-60, #326297);
}

.sync-btn {
  gap: 8px;
  min-width: 254px;
  border: none;
  box-shadow: none;
}

.sync-btn:focus-visible {
  box-shadow: 0 0 0 1px #326297, 0 0 0 4px #fff, 0 0 0 7px #9263de;
}

@media (max-width: 767px) {
  .report-button-group-wrapper,
  .report-button-group-button,
  .sync-btn {
    width: 100%;
  }
}