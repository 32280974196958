.text-component {
  ul {
    padding: 0;
  }

  li {
    display: flex;
    list-style-type: none;
  }
}

.text-component {
  padding: 0;
  margin: 0;
}

.text-component li {
  margin: 2rem 0;
  display: flex;
  align-items: flex-start;
}

.text-component :last-child {
  margin: 2rem 0rem 1rem 0rem;
}

.text-component li:before {
  content: url(../../assets/icon-tick.svg);

  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
  height: 100%;
  width: 20px;
  padding: 2px;
}

// variant for new authorisation page
.text-component.tiledList {
  h3 {
    letter-spacing: -0.02em;
    line-height: 34px;
    font-size: 23px;
  }

  .content-tile-list-item {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    display: flex;

    &::before {
      content: url(../../assets/icon-tick.svg);
      display: inline-block;
      height: 100%;
      width: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 10px;
      padding: 2px;
    }
  }
}
