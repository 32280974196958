.report-instructions {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.report-side-nav-wrapper {
  position: sticky;
  // when nav wrapper is moved from side to above
  // it extends over text content on expand.
  @media (max-width: 767px) {
    z-index: 1;
  }
}

.report-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-left: 0px;
  margin-top: 48px;
  color: #304050;
}

.trading-period {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #304050;
}

.report-last-save {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 20px;
  font-style: italic;
}

.action-btn {
  color: #326297;
  font-weight: 600;
  border: 1px solid #326297;
  margin: 0;
  padding: 0 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px #0000001a;
}

.action-btn.amplify-button:focus-visible {
  background-color: #ffffff;
  box-shadow: 0 0 0 4px #fff, 0 0 0 7px #9263de;
}

.sync-btn[data-loading='true']::before {
  display: none;
}

.sync-inline-error::before {
  padding-top: 2px;
}

.report-main-container {
  padding: 0 0 65px 0;

  .heading-text {
    font-size: 24px !important;
    line-height: 32px !important;
  }

  @media (max-width: 448px) {
    padding-left: var(--amplify-space-large);
    padding-right: var(--amplify-space-large);
  }
}

.report-step-header {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  margin: 0;
}

.report-paragraph {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  vertical-align: middle;
  margin-top: 24px;
  margin-bottom: 36px;
  color: #71767d;
}

.report {
  position: relative;
  height: 100%;
}

.profit-loss-minified-divider {
  border-bottom: 1px solid #71767d;
  margin: 4px 0 4px auto;
}

.profit-loss-minified-amounts {
  color: #71767d;
}

.back-to-top-button {
  border: none;
  text-decoration: underline;
  color: #0f487c;
  font-size: 16px;
  line-height: 24px;
}

@media (prefers-contrast: more) {
  .report path {
    stroke: currentColor;
  }
}

.report-input-field input,
.report-input-field select,
.report-input-field input:not(.amplify-input--error) {
  border: 2px solid var(--amplify-colors-border-primary, #5C6670);
  border-radius: 8px;
}

.report-input-field input:disabled,
.report-input-field select:disabled {
  border: none;
  background-color: unset;
}

@media screen and (max-width: 767px) {
  .report-header {
    max-width: 80%;
  }
  .back-to-top-button-container {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .report-header {
    max-width: 60%;
  }
  .trading-name {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}
