.input-label-tooltip-button {
  height: 24px;
  width: 24px;
  border: none;
  background: url(../../assets/tooltip/tooltip-default.svg) no-repeat center;

  &:hover {
    background-image: url(../../assets/tooltip/tooltip-hover.svg);
    background-color: transparent;
  }

  &:focus {
    box-shadow: 0 0 0 2px #9263de;
    border-radius: 0;
    background-color: #ffffff;
  }

  &.selected {
    background-image: url(../../assets/tooltip/tooltip-select.svg);
  }
}
