.instruction-card {
  width: 100%;
  border: 1px solid #ebeced;
  border-radius: 8px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px;

  @media (max-width: 767px) {
    padding: 16px;
  }
}

.instruction-heading {
  margin: 24px 0px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: #326297;
}

.instruction-text > p:not(:last-child) {
  margin-bottom: 25px;
}
