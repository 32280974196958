.claim-obligation-container {
  margin: 0;
  /* Gap is removed at this width. Adding padding to containers to center content */
  @media (max-width: 448px) {
    padding: 0px var(--amplify-space-medium);
  }
}

.claim-obligation-content {
  gap: 16px;

  .breadcrumb {
    width: fit-content;
  }
}

.claim-obligation-header {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0d1926;
}

.claim-obligation-body-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  font-variation-settings: 'wdth' 100;
  color: #0d1926;
}

.claim-obligation-body-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #0d1926;
}

.claim-obligation-seperator {
  border-bottom: 1px solid #ededed;
  margin-right: 8px;
}

.claim-obligation-input input {
  min-height: 46px;
  width: 208px;
  border-radius: 8px;
  border-color: rgba(92, 102, 112, 1);
}

.claim-obligation-input .amplify-field__error-message::before {
  content: url(../../assets/icon-alert.svg);
  margin-right: 5px;
}

.claim-obligation-container:after {
  content: '';
  display: block;
  height: 48px;
}

.claim-obligation-input .amplify-field__error-message {
  color: #dc2626;
  font-size: 16px;
}

.claim-obligation-input .amplify-input--error {
  border-color: #dc2626;
}

.claim-obligation-submit-btn {
  min-width: 192px;
  min-height: 48px;
  border-radius: 8px;
}

.claim-obligation-back-link {
  color: rgba(50, 98, 151, 1);
  text-decoration: none;
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  font-variation-settings: 'wdth' 100;
}

.claim-obligation-back-link:hover {
  cursor: pointer;
}

.claim-obligation-comparison {
  font-size: 19px;
  color: #326297;
  font-size: 19px;
  font-weight: 600;
  line-height: 34px;
}

.saving-rotate-icon {
  animation: saving-rotate-loading 1s linear infinite;
}

@media (max-width: 767px) {
  .claim-obligation-input input {
    width: 100%;
  }

  .claim-obligation-submit-btn {
    width: 100%;
  }

  .claim-obligation-comparison {
    min-height: 40px;
  }
}
