

.menu-wrapper1 {
  padding: var(--amplify-space-xxl, 3rem) 0px var(--amplify-space-xxxl, 4.5rem);

  height: 100%;

  .amplify-grid > .amplify-card {
    padding: 0;
    margin: 0;
  }

  // to align with wireframes of auth page
  a[target="_blank"]:after {
    padding-left: 5px;
  }

  .alert-content {
    max-width: unset;
  }

  .banner .alert-component-warning, .banner .alert-text-header {
    width: 100%;
    min-width: 100%;
  }
}
