.alert-component {
  margin: 0px;
  padding: 12px 0px;

  .amplify-alert__heading {
    padding: 12px 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
}

.alert-component-default .amplify-alert__icon,
.alert-component-error .amplify-alert__icon {
  content: url(../../assets/icon-info.svg);
  width: 33px;
  height: 33px;
  margin: 0px;
  padding: 0px;
}

.alert-component-time-out {
  display: flex;
}

.alert-component-time-out .amplify-alert__icon {
  content: url(../../assets/icon-clock.svg);
  width: 33px;
  height: 33px;
  margin: 0px;
}

.alert-component-warning .amplify-alert__icon {
  content: url(../../assets/icon-info.svg);
  width: 33px;
  height: 33px;
}

.alert-component-warning {
  margin-top: 0px;
  background-color: #fef3c7;
  color: #000;
}

.alert-component-error {
  margin-top: 0px;
  background-color: #fee2e2;
  color: #000;
}

.alert-text-header {
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.alert-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.alert-texts {
  margin: 0px;
}

.alert-texts li {
  padding-bottom: 12px;
}

.amplify-alert__dismiss {
  align-self: flex-start;
  border: none;
  padding: 12px;

  .amplify-icon {
    content: url(../../assets/icon-x.svg);
    transform: scale(1.7);
  }
}

.alert-error-code {
  color: #5c6670;
}

@media (max-width: 767px) {
  .alert-component {
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    align-items: flex-start;
    position: relative;
  
    .amplify-alert__dismiss {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  
    .amplify-field-group__icon .amplify-icon {
      display: block;
    }
  }
}
