.error-page-title {
  font-weight: 600;
  font-size: 100px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #326297;
}

.error-pages {
  text-align: center;
  width: 656px;
}

.error-container {
  height: calc(100vh - var(--top-nav-menu));
}

.error-page-summary {
  font-weight: 600;
  font-size: 36px;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin-bottom: 20px;
}

.error-page-content {
  font-weight: 300;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  margin-bottom: 50px;
}

.page-not-found-404 {
  width: 1440px;
  height: 1024px;
  margin: 0 auto;
  padding: 0;
}

.page-not-found-right-img {
  display: inline-block;
  margin-top: 85px;
  width: 50%;
}

.page-not-found-left {
  width: 50%;
}

.page-not-found-title {
  font-weight: 600;
  font-size: 100px;
  line-height: 160%;
  letter-spacing: 0.15px;

  color: #326297;
}

.page-not-found-summary {
  font-weight: 600;
  font-size: 36px;
  line-height: 160%;
  letter-spacing: 0.15px;
  margin-bottom: 40px !important;
}

.page-not-found-content {
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}

.contact-us-link {
  text-decoration: none;
}

.page-not-found-btn {
  height: 46px;
  width: 174px;
}