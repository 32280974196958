.left-contour-line-primary {
  position: relative;
}

.left-contour-line-primary::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10px;
  background-color: var(--amplify-colors-brand-primary-60);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.benefit-icon {
  --amplify-components-image-width: 64px;
  --amplify-components-image-height: 64px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 115px;
  height: 94px;
  flex: none;
  flex-grow: 0;
}

.benefit-icon + .benefit-icon:before {
  content: '';
  position: absolute;
  left: -11px;
  display: block;
  background-color: #ebeced;
  width: 2px;
  height: 50px;
  top: 50%;
  transform: translateY(-50%);
}
