.video-responsive {
  overflow: hidden;
  position: relative;
  border: 0;
  aspect-ratio: 16/9;
}

.video-responsive iframe {
  width: 100%;
  height: 100%;
}