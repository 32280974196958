.save-debug-ui {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 99999;
  padding: 0;
  background-color: moccasin;

  font-size: 0.6em;
  margin: 0;
  padding: 0;
  opacity: 0.7;
}

.save-debug-ui > ul {
  margin: 0;
  padding: 2px 8px;
}
