.inactivity {
  position: absolute;
  z-index: 1;
  background-color: #ffffff;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  overflow: unset;
  width: 667px;
  height: 559px;
  border: 1px solid #EDEDED;
  box-shadow: 0px 20px 24px -4px rgba(0, 0, 0, 0.1), 0px 8px 8px -4px rgba(0, 0, 0, 0.04);
  border-radius: 16px;

  &:focus-visible {
    outline: none;
  }

  .heading {
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 15px;
  }

  .content {
    font-weight: 400;
    width: 350px;
    color: #666666;
    margin: 0 auto;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }

  .count-down {
    width: 300px;
    margin: 36px auto 62px;
  }

  .amplify-button--primary,
  .save-and-log-out-btn {
    min-width: 160px;
  }

  .amplify-button--link {
    border: 1px solid #2C5684;
  }

  .react-countdown-clock {
    margin: 36px auto 50px;
  }
}

.clock-icon {
  display: block;
  width: 60px;
  height: 60px;
  margin: 60px auto 24px;
}

.timeout-svg {
  transform: rotateY(-180deg) rotateZ(-90deg) scaleY(-1);
  width: 144px;
  height: 144px;
  z-index: 1000;
}

.timeout-circle {
  stroke-dasharray: 414px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 12px;
  stroke: #326297;
  fill: none;
}

.timeout-circle-track {
  stroke-dasharray: 414px;
  stroke-width: 12px;
  stroke: #F3F7FB;
  fill: none;
}

.countdown-text {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 44px);

  color: var(--Black, #070417);
  text-align: center;

  /* Heading/05/Semi Bold */
  font-family: Open Sans;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.46px;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 408px;
  }
}