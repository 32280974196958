
.postcode-search-component {
  --amplify-components-fieldcontrol-padding-inline-start: 19px;
  --amplify-components-fieldcontrol-error-border-color: #DC2626;
  --amplify-components-fieldcontrol-padding-block-start: 7px;
  --amplify-components-fieldcontrol-padding-block-end: 7px;
  --amplify-components-fieldcontrol-line-height: 22px;
  --amplify-components-fieldcontrol-error-focus-box-shadow: rgb(220 38 38) 0px 0px 0px 1px;
  --amplify-components-fieldcontrol-border-radius: 8px;
  line-height: 22px;

  /* autocomplete component */
  --amplify-components-autocomplete-menu-margin-block-start: -7px;
  --amplify-components-autocomplete-menu-border-radius: 8px;
  --amplify-components-autocomplete-menu-space-shared-padding-block: 8px;
  --amplify-components-autocomplete-menu-space-shared-padding-inline: 16px;
  --amplify-components-autocomplete-menu-options-max-height: 162px;
}

.postcode-search-component .amplify-searchfield {
  padding-top: 7px;
  padding-bottom: 7px;
  width: 100%;

  .amplify-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}

/* Clear button is not present on the design */
.postcode-search-component .amplify-searchfield .amplify-button {
  display: none;
}

.postcode-search-component .amplify-searchfield .amplify-field__error-message {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  color: #DC2626;
  font-size: 16px;
  line-height: 22px;
}

.postcode-search-component .amplify-searchfield .amplify-field__error-message::before {
  content: url(../../assets/icon-alert.svg);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  width: 14px;
}

.postcode-search-component .amplify-autocomplete {
  /* flex: 0 0 329px; */
  width: 100%;
}

.postcode-search-component .amplify-autocomplete .amplify-input {
  padding: 12px 16px;
  background-color: #FFFFFF;
  border: 1px solid var(--amplify-colors-border-primary, #5C6670);
  border-radius: 8px;
}

/* magnifying glass icon not in design, but handled with prop on component? */
.postcode-search-component .amplify-autocomplete .amplify-field-group__inner-start {
  display: none;
}

.postcode-search-component .amplify-autocomplete.menu-above .amplify-autocomplete__menu {
  bottom: 45px;
}

.postcode-search-component.hide-option-dropdown .amplify-autocomplete__menu {
  /* don't show with error, but ensure position checking still works in background */
  opacity: 0;
}

.postcode-search-component .amplify-autocomplete__menu__option {
  min-height: 35px;
}
