@import url('utils/animation.css');

.dashboard-wrapper {
  background: #d9d9d91a;
  width: 100%;
}

.dashboard-header-wrapper {
  width: 100%;
}

.dashboard-header-wrapper h6 {
  word-wrap: break-word;
  min-width: 100%;
  max-width: 100%;
}

.dashboard-data-wrapper {
  min-width: 100%;
  max-width: 100%;
}

.dashboard-nav div {
  margin: 0;
}

.dashboard-abn-text {
  color: #000000;
}
/* These tabs style both dashboard and for instructions tabs so this style works for instructions but has made the dashboard break, 
we need to split these out to their own styles when the dashboard redesign is done. */
.instructions-tab {
  margin-top: -16px;
  --instructions-item-height: 60px;
  flex-wrap: wrap;
  direction: row;
  max-width: 838px;
  background: repeating-linear-gradient(
    #fff,
    #fff
      calc(
        var(--instructions-item-height) -
          var(--dashboard-report-tabs-bottom-border)
      ),
    #dcdee0
      calc(
        var(--instructions-item-height) -
          var(--dashboard-report-tabs-bottom-border)
      ),
    #dcdee0 var(--instructions-item-height)
  );
}

.instructions-tab > .amplify-tabs-item {
  height: var(--instructions-item-height);
  margin: 0;
}

.instructions-tab {
  --instructions-item-height: 60px;
  flex-wrap: wrap;
  direction: row;
  max-width: 838px;
  margin-bottom: 48px;
  background: repeating-linear-gradient(
    #fff,
    #fff
      calc(
        var(--instructions-item-height) -
          var(--dashboard-report-tabs-bottom-border)
      ),
    #dcdee0
      calc(
        var(--instructions-item-height) -
          var(--dashboard-report-tabs-bottom-border)
      ),
    #dcdee0 var(--instructions-item-height)
  );
}

.instructions-tab > .amplify-tabs-item {
  height: var(--instructions-item-height);
  margin: 0;
}

.amplify-tabs {
  border: none;
}

html {
  --dashboard-report-tabs-bottom-border: 4px;
}

.amplify-tabs-item:focus-visible {
  margin-bottom: calc(var(--dashboard-report-tabs-bottom-border) * -1);
  border: 2px solid #9263de;
  border-bottom-width: var(--dashboard-report-tabs-bottom-border);
  outline: 0;
}

.amplify-tabs-item {
  font-size: 16px;
  font-weight: normal;
  color: #0f487c;
  line-height: 24px;
  padding: 16px;
}

.amplify-tabs-item[data-state='active'] {
  font-weight: 400;
  color: #0D1926;
  margin-bottom: calc(var(--dashboard-report-tabs-bottom-border) * -1);
  border-bottom-width: var(--dashboard-report-tabs-bottom-border);
}

.dashboard-dsp-name-heading {
  font-size: 16px;
  line-height: 24px;
  color: #5C6670;
  font-weight: 400;
}

.empty-report-list {
  text-align: center;
  margin: 96px 0;
  color: #71767d;
}

.tab-container {
  border-bottom: #EFF0F0 solid 1px;
}

@media screen and (max-width: 767px) {
  .instructions-tab {
    width: 100%;
    background: transparent;
  }

  .amplify-tabs-item:not(.dashboard-tab) {
    width: 100%;
    text-align: left;
    background: transparent;
  }
}
