.question-component {
  padding: 0;
  gap: 6px;
  display: grid;

  .amplify-textarea--error {
    border: 2px solid #dc2626;
  }

  .question-input-field {
    word-wrap: break-word;
  }

  .question-title {
    font-weight: 400;
    font-size: 16px;
    color: #10002e;
    margin: 1rem 0 0 0;
  }

  .question-limit-footer {
    color: #736a85;
  }
}

.question-component-minified {
  font-weight: 600;
}

.text-area-highlighted {
  --amplify-components-fieldcontrol-focus-border-color: #d97706;
  --amplify-components-fieldcontrol-border-color: #d97706;
  --amplify-components-fieldcontrol-focus-box-shadow: none
}

.text-highlighted {
  color: #d97706 !important; // to overide .question-limit-footer
}

.text-highlighted::before {
  content: url(../../../assets/icon-warning-round.svg);
  margin-right: 5px;
}
