.detailed-topic-component {
  width: 100%;
  padding-bottom: 48px;
  border-bottom: 1px solid #EFF0F0;
}

.detailed-topic-title-text {
  margin-right: auto;
  margin-top: 32px;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.02em;
}

@media (max-width: 767px) {
  .detailed-topic-title-text {
    font-size: 28px;
    line-height: 36px;
  }
}

.detailed-topic-title-link p,
.detailed-topic-title-link:active {
  color: #326297;
}

.detailed-topic-title-total {
  font-size: 23px;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-weight: 600;
}
