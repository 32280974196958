.top-remaining-no-dsp-inputs {
  .amplify-label {
    color: #000;
    font-weight: 600;
  }

  .amplify-field-group__field-wrapper--horizontal {
    display: flex;
    align-items: center;
  }

  .amplify-field-group__inner-start {
    font-size: 16px;
    height: fit-content;
    top: unset;
    padding-left: 12px;
  }
  
  .amplify-field-group--has-inner-start .amplify-input {
    padding-left: 22px;
  }
}