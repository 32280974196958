.skip-link {
    margin: 8px;
    position: absolute;
    transform: translateX(-200%);
    transition: transform 0.3s;
    z-index: 1001;

    &:focus {
        position: fixed;
        transform: translateX(0);
    }
}