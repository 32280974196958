/* should have a more general name like topic total */
.income-expenses-total {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  letter-spacing: -0.02em;
  line-height: 34px;
}

.profit-total-heading {
  margin-top: 30px;
}

.width-640 {
  max-width: 640px;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.sub-head {
  font-size: 18px;
  font-weight: 400;
  color: #1a1919;
}

.sub-desc {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #4b4f54;
}

.bold-total {
  font-size: 20px;
  font-weight: 700;
}

.seperator {
  border-bottom: 1px solid #ededed;
  margin-top: 30px;
  margin-bottom: 30px;
}

.income-expenses-total .tooltip-icon {
  margin-left: 8px;
  margin-right: 0px;
  padding: 0;
  border: none;
}

.income-expenses-total .tooltip-icon:hover {
  background-color: initial;
}

.income-expenses-total .tooltip-icon:focus {
  box-shadow: 0 0 0 2px #9263de;
  border-radius: 0;
  background-color: #ffffff;
}

.total-amount > .report-list-container {
  border-top: 0;
}

.total-amount > .report-list-container ~ .report-list-container {
  border-top: 1px solid #EFF0F0;
}