.remaining-items-table-right-col {
  text-align: right;
  color: #71767D;
}

.remaining-items-table {
  margin-top: 4px;
}

.remaining-items-table .amplify-table__th,
.remaining-items-table .amplify-table__td {
  padding: 0 !important;
}
