.review-report {
  display: flex;
  flex-direction: column;
  gap: var(--amplify-space-large);

  .amplify-switch-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-right: 24px;
  }

  .amplify-switch-track {
    width: 51px;
    height: 31px;
    background-color: #DCDEE0;
  }

  .amplify-switch-thumb {
    height: 27px;
    width: 27px;
    margin: 2px;
  }

  .amplify-switch-track--checked {
    background-color: #5C6670;
  }

  .alert-component:not(:first-child) {
    margin-top: 10px;
  }

  .detailed-topic-component {
    gap: 32px;
    display: flex;
    flex-direction: column;
  }
}

.button-action-download {
  color: #326297;
  padding: 12px;
  border: 2px solid #326297;
  gap: 4px;
  border-radius: 8px;
}

.button-action-download:before {
  color: #326297;
  vertical-align:top;
  content: url(../../../assets/icon-download-pdf.svg);
  line-height: 1;
}

.contact-details-header {
  justify-content: space-between;
  margin-top: auto;
  border-top: 1px solid #ebeced;
  padding: 24px 0;
}

.contact-details-heading {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

.contact-details-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0D1926;
}

.contact-details-edit-btn {
  border: none;
  text-decoration: underline;
  color: #0F487C;
  font-weight: 400;
  padding: 4px;
  justify-content: start;
}

.contact-details-edit-btn:hover {
  background-color: transparent;
}
