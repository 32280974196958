.inline-error {
  display: inline-block;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #dc2626;

  // To allow positioning the icon while having wrapped text align at left edge
  position: relative;
  // 14px icon, 10px spacing from text
  padding-left: 24px;
  // Align with text to the left
  bottom: 15px;

  // Allow the default error message to break between the sentences
  span {
    display: inline-block;
  }
}

.inline-error::before {
  content: url(../../assets/icon-alert.svg);
  background-size: cover;
  background-repeat: no-repeat;

  position: absolute;
  left: 0;
}

.report-button-inline-error {
  text-align: right;
  margin-right: 102px;
}

.dashboard-navbar-inline-error {
  margin-top: 20px;
}

.dashboard-report-inline-error {
  position: absolute;
  top: 70px;
  right: 0;
}

.time-out-alert-inline-error {
  display: block;
  margin-top: 20px;
  text-align: center;
}
