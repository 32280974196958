.dashboard-info-heading {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: #304050;
}

.dashboard-info-data {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  letter-spacing: -0.02px;
  text-align: left;
  color: #0D1926;
  overflow-wrap: break-word;
}

.dashboard-info-wrapper {
  min-width: 100%;
  max-width: 100%;
}