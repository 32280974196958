.reportlist-nodsp-input {
  max-width: 220px;
  margin-left: auto;
  margin-bottom: 46px;
  gap: 0;

  .amplify-field__error-message {
    text-align: left;
  }

  .amplify-field-group__field-wrapper--horizontal {
    display: flex;
    align-items: center;
  }

  .amplify-field-group--has-inner-start .amplify-input {
    padding-left: 22px;
  }

  .amplify-field-group__inner-start {
    font-size: 16px;
    height: fit-content;
    top: unset;
    padding-left: 12px;
  }
}
