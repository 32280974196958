.industry-insights-page-heading {
  font-size: 40px;
  line-height: 48px;
  letter-spacing: -0.02em;
  font-weight: 600;
  color: #0D1926;
}

.industry-insights-page-description{
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.add-business-button {
  min-width: 174px;
  min-height: 46px;
  margin: 48px 0;
}

.insight-toggle-switch-input-container {
  .amplify-switch-label {
    display: none;
  }
}

.insight-measure-heading{
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.02em;
}
