@import url('utils/animation.css');

.business-select-wrapper {
  width: 100%;
  position: relative;
  display: inline-block;
  height: 100%;
  max-height: 48px;
}

.business-select-input__control {
  height: 100%;
  max-height: 48px;
  padding: var(--amplify-space-small) var(--amplify-space-medium) ;
  align-items: center;
  vertical-align: middle;
}

.business-select-input__input {
  padding: 0px;
}

.business-select-button-text {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #666;
}

.business-select-input__indicator {
  padding: 0px;
}

.business-select-button {
  padding: 8px 16px;
  height: 51px;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 #0000001a;
  background-color: #fff;
  width: 100%;
  border: none;
  cursor: pointer;
}

.business-select-search-placeholder p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #a5a5a5;
}

.business-select-search-wrapper {
  margin-top: 12px;
  position: absolute;
  width: 100%;
}

.business-select-menu {
  border: solid 1px #ebeced;
  background-color: #fff;
  cursor: default;
  border-radius: 8px;
}

.business-select-group > p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  padding: 16px 16px 12px;
}

.business-select-group:first-child {
  border-bottom: solid 1px #ebeced;
}

.business-select-menu-wrapper {
  position: absolute;
  width: 100%;
  margin-top: 12px;
  margin-bottom: -24px;
}

.business-select {
  width: 100%;
  margin-right: -40px;
  margin-bottom: -24px;
  box-shadow: 0px 4px 8px #0000001a;
  border-radius: 8px;
}

.business-select-empty-list {
  width: 100%;
  text-align: center;
  padding-bottom: 12px;
}

.business-select-wrapper .amplify-label {
  padding-bottom: 4px;
}

.select-label {
  margin-top: -24px;
}
