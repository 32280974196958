/* .contact-input-field:not(.role-input) { */
.contact-input-field {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  align-items: center;
  gap:0;
  .amplify-field-group--horizontal,
  .amplify-select__wrapper {
    margin-left: 0;
  }

  border-bottom: 1px solid #EFF0F0;
  min-height: 48px;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
}

.contact-input-field:first-child {
  border-top: 1px solid #EFF0F0;
}

.email-input .amplify-field-group--horizontal {
  width: unset;
  margin-left: unset;
}

.email-input input {
  border: none !important;
  padding-right: 0;
  color: #475569;
  padding-left: 0px;
}

.contact-input-field label {
  color: #10002e;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  min-height: 42px;
  display: flex;
  align-items: center;
}

.contact-input-field input {
  min-height: 42px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0D1926;
}

.role-input select {
  text-align: left;
  /* Safari Fix for right alignment */
  &:disabled {
    direction: rtl;
  }
}

.contact-input-field .amplify-input--error {
  border-color: #dc2626;
}

.contact-input-field .amplify-field__error-message {
  color: #dc2626;
  font-size: 16px;
  font-weight: 400;
  grid-column-start: 2;
  justify-self: end;
}

.contact-input-field .amplify-field__error-message::before {
  content: url(../../assets/icon-alert.svg);
  margin-right: 5px;
}

.contact-input-field input:disabled,
.contact-input-field select:disabled {
  background-color: unset;
  color: #000;
  padding-left: 0;
}

.contact-details-btn {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  padding: 0;
}
