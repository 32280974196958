.report-save-status-container {
  width: 120px;
  height: 28px;
  padding: 0;
  border-radius: 8px;
}

@keyframes saving-rotate-loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.saving-rotate-icon {
  animation: saving-rotate-loading 1s linear infinite;
}
