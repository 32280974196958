.tooltip-component {
  padding: 0px;
  margin: 0px;

  .title-text {
    font-weight: 600;
    font-size: 16px;
  }

  .heading-text {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
    // equivalent of -0.02em at font size 23px
    letter-spacing: -0.46px;
  }

  .helptext {
    font-weight: 400;
    color: #4b4f54;
  }
  
  .helptext:not(:first-child) {
    margin-top: 8px;
  }

  // the info button
  .amplify-button {
    margin-right: -4px;
    padding: 0;
    height: 28px;
    width: 28px;
    border: none;

    &:hover {
      background-color: initial;
    }

    &:focus {
      box-shadow: 0 0 0 2px #9263de;
      border-radius: 0;
      background-color: #ffffff;
    }
  }

  .title-text + .amplify-card {
    padding: 0px;
  }

  .heading-text + .amplify-card {
    padding: 0;
    // prevents heading height expanding excessively
    line-height: 0;
  }

  .help-texts-card {
    margin: 0px;
    margin-top: 12px;
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .tooltip-component {
    .heading-text {
      font-size: 28px;
      line-height: 36px;
    }
  }
}
