.report-list-container {
  padding: 0px 0px 0px 0px;
  display: grid;
  gap: 2rem;
}

.amplify-expander__content__text {
  display: grid;
}

.report-list-container:before {
  content: "";
}

.report-list-container:after {
  content: "";
  height: 13px;
}

.amplify-table__row {
  border-bottom: 1px solid #EFF0F0;
}

.report-list-btn-row {
  /* Makes buttons the same width, based on the widest content */
  display: grid;
  /* Make buttons same size */
  grid-auto-columns: 1fr;
  /* Make enough columns for the buttons */
  grid-auto-flow: column;
  /* Shrink as much as possible, until button with widest content is not stretched */
  width: max-content;

  grid-gap: 20px;

  margin-top: 0;
  padding-bottom: 1px;
}

.report-list-add-btn {
  min-width: 173px;
  min-height: 48px;
  border-radius: 8px;
  border: 2px solid var(--amplify-colors-brand-primary-60, #326297);
  font-weight: normal;
  font-size: 14px;
  box-shadow: 0px 2px 4px 0px var(--amplifycolorsshadowtertiary);
  color: var(--amplify-colors-font-interactive, #0F487C);
  
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  font-variation-settings: 'wdth' 100;
  width: fit-content;
}

.report-list-add-btn > svg,
.report-list-add-btn > img {
  margin-right: 10px;
  height: 24px;
}

.amplify-table__th {
  border: none;
  font-weight: 600;
}

.add-from-dsp-table {
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
  border: solid 1px #eff0f0;
}

.add-from-dsp-table tbody {
  display: block;
  width: 100%;
  min-width: 240px;
  overflow-y: auto;
  overflow-x: unset;
  max-height: 512px;
}

.add-from-dsp-table thead {
  width: 100%;
}

.add-from-dsp-table tr {
  width: 100%;
  display: flex;
  border-bottom: solid 1px #eff0f0;
}

.amplify-table__td {
  border: none;
  padding-top: 12px;
  padding-bottom: 12px;
  vertical-align: middle;
  margin: auto;
}

.mapped-items-table {
  // needed for specificity to override default amplify style
  .amplify-table__td:first-child,
  .amplify-table__th:first-child {
    padding-left: 0;
  }

  // To make the sizing simpler with the border between the header and first row
  border-collapse: collapse;
  border-spacing: 0;

  width: 100%;
  $row-height: 44px;
  $line-height: 19px;
  $padding-height: calc($row-height - $line-height);

  font-weight: 400;
  font-size: 16px;
  line-height: $line-height;

  display: table-column-group;
  overflow: auto;
  

  th,
  td {
    text-align: left;
  }

  th {
    vertical-align: top;
    padding-top: 0;
    padding-bottom: $padding-height;
  }

  td {
    vertical-align: middle;
    $verticalPadding: calc($padding-height / 2);
    padding-top: $verticalPadding;
    padding-bottom: $verticalPadding;

    &.report-list-summary-total-table-cell {
      font-weight: 600;
      text-align: right;
    }
  }
}

.wrap-cell-content {
  width: 1%;
  white-space: nowrap;
}

.report-list-action-th {
  min-width: 70px;
}

.report-list-action-th span {
  text-align: center;
}

.report-list-item-action-btn {
  // top padding adjustment is for better vertical centering of icon
  padding: 5px 0 0 0;
  border: none;
}

.report-list-actions-menu {
  color: #0F487C;
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.06);
  border: 1px solid #ededed;
}

.report-list-actions-menu > button:not(:last-child) {
  border-bottom: 1px solid #ededed;
}

.report-list-actions-menu > button {
  font-weight: normal;
}

.report-list-actions-menu > button > svg {
  margin-right: 8px;
}

.report-list-actions-menu .amplify-menu-content__item:hover:not(:disabled),
.report-list-actions-menu .amplify-menu-content__item:focus {
  background-color: #a6d8ff;
  color: unset;
  text-decoration: underline;
}

.report-list-summary-cell {
  text-align: right;
  padding-top: 24px;
  font-weight: 600;
  vertical-align: bottom;
}

.report-list-summary-cell-empty-value {
  padding: 0;
  text-align: right;
  font-weight: 600;
  vertical-align: bottom;
}
.report-list-empty-items-row {
  padding: 0;
  height: 102px;
}

// amplify-table__td included to increase specificity
.amplify-table__td.report-list-empty-items-cell {
  width: 100%;
  padding: 0;
  text-align: center;
  color: #71767d;
}

.close-icon-btn {
  align-self: flex-end;
  padding: 0;
  border: none;
}

.add-from-dsp-items-filter {
  margin-left: auto;
  width: 100%;
  min-width: 250px;
}

.add-from-dsp-heading {
  font-size: 20px;
  font-weight: 400;
}

.allocate-accounts-btn {
  min-width: 182px;
  width: 100%;
}

.amplify-field-group__inner-end button {
  border-radius: 0 4px 4px 0;
  border-left-style: none !important;
}

.manual-add-action-btn {
  border-radius: 8px;
  width: 32px;
  height: 32px;
  padding: 0;
}

// some visually hidden amplify labels were overflowing horizontally
.total-amount {
  .amplify-table {
    .amplify-visually-hidden {
      left: 1px;
    }
  }
}

.manual-add-input-field .amplify-field-group__inner-start {
  left: 5px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.manual-add-input-field input {
  padding-inline-start: 16px !important;
}

.manual-add-input-field:has(p) {
  padding-bottom: 7px;
}

.manual-input-row td {
  vertical-align: top;
}

.manual-input-row .manual-add-input-source {
  padding-top: 48px;
}

.manual-input-row .manual-add-action-cell {
  padding: 44px 0 16px 0;
}

.manual-input-row .manual-add-input-field {
  padding-top: 4px;
}

.manual-add-action-cell button {
  border-color: #878889;
  box-shadow: 0px 5px 3px -2px rgba(0, 0, 0, 0.02),
    0px 3px 1px -2px rgba(0, 0, 0, 0.06);
}

.amplify-expander__item {
  outline: none !important;
  border: none !important;
}

.report-list-expander:focus {
  border: none;
}

.report-list-expander:focus-within {
  border: none;
  box-shadow: none;
}

.report-list-expander {
  border: none;

  .amplify-expander__trigger {
    flex-direction: row-reverse;
    font-size: 16px;
    font-weight: 600;
    gap: 4px;
    padding: 0;
    min-height: 64px;
    background-color: #FFFFFF;

    .amplify-expander__icon {
      font-size: 28px;
      .amplify-icon {
        display: block !important;
      }
    }
  }

  .amplify-expander__trigger:hover {
    cursor: pointer;
  }

  .amplify-expander__content {
    padding: 0;
  }
}

.amplify-expander,
.amplify-expander__header {
  box-shadow: none;
}

.amplify-expander__content__text {
  padding-top: 0;

  .amplify-table__body,
  .amplify-table__row {
    height: unset !important;
  }
}

.no-accounts-minified {
  color: #71767d;
  padding-left: 8px;
}

.top-remaining-help-text {
  color: #71767d;
  padding-left: 12px;
}

.top-remaining-help-text-bottom {
  color: #71767d;
  margin-bottom: 32px;
  padding-left: 12px;
}

.asterix {
  color: #71767d;
  position: absolute;
  margin-top: 4px;
}

#add-accounts-filter-label {
  padding-bottom: 4px;
}

.button-box {
  justify-content: right;
}

.modal-logo {
  position: absolute;
}

.modal-content {
  margin-top: 1rem;
}

.account-name {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.account-code {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.modal-gap {
  gap: 1rem;
}

.scroll-shadows {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  background:
    /* Shadow Cover TOP */ linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    /* Shadow TOP */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center top,
    /* Shadow BOTTOM */ radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

/* Not important to the functionality, just for this particular demo */
.scroll-shadows {
  list-style: none;
  padding-right: 0.5rem;
}
.scroll-shadows > * {
  padding: 0.2rem;
}
.scroll-shadows {
  --scrollbarBG: transparent;
  --thumbBG: #90a4ae;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.scroll-shadows::-webkit-scrollbar {
  width: 6px;
}
.scroll-shadows::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.scroll-shadows::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
@media (prefers-contrast: more) {
  .report-list-add-btn path {
    fill: currentColor;
  }
}

@media (max-width: 1279px) {
  .add-from-dsp-table tbody {
    display: block;
    width: 100%;
    overflow-y: auto;
    overflow-x: unset;
  }

  .modal-gap {
    gap: 1rem;
  }

  .mapped-items-table {
    background-image: 
	
    /* Shadows */ 
    linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
  
    /* Shadow covers */ 
    linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),
    linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0)); 

    background-position: left center, right center, left center, right center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
    
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
    
}

@media only screen and (min-width: 769px) {
  .allocate-accounts-btn {
    max-width: 200px;
  }
  .modal-gap {
    gap: 2rem;
  }
}

@media (max-width: 767px) {
  .report-list-btn-row {
    grid-auto-flow: row;
    width: 100%;
  }

  .report-list-add-btn {
    width: 100%
  }

}
