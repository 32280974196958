.container-grid {
  margin: 0;
  @media (max-width: 448px) {
    padding: 0px var(--amplify-space-small);
  }
}

.select-business-card {
  padding: 0px;
}

.select-business-heading {
  color: #0d1926;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
}

.select-business-text {
  color: #0d1926;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.options-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  font-variation-settings: "wdth" 100;
  color: #0d1926;
}

.business-reports-setting-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.select-business-text:not(:last-of-type) {
  margin-bottom: 20px;
}

.select-business-permissions {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.select-business-divider {
  border-color: #ebeced;
}

.select-business-label {
  font-weight: 700;
  margin-bottom: 8px;
}

.select-business-btn {
  border-radius: 8px;
  width: 196px;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  font-variation-settings: "wdth" 100;
  background-color: rgba(50, 98, 151, 1);
}

.connect-business-btn-container:after {
  content: "";
  display: block;
  height: 48px;
}

.select-business-search-icon {
  margin-left: 10px;
}

.select-business-inline-error {
  margin-top: 4px;
  text-align: center;
  padding-left: 20px;
}
