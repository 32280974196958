.menu-wrapper {
  width: 100%;
  height: 100%;
}

.menu-outer-wrapper {
  width: 100%;
  height: var(--top-nav-menu);
  background: #ffffff;
  border-bottom: 1px solid #ebeced;
  z-index: 1000;
}

.amplify-heading {
  margin: auto 0;
  padding: 0;
}

.menu-header-home {
  color: #000000;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 700;
  margin: auto;
  line-height: 79px;
  height: 79px;
  padding: 0 10px;
  border-bottom: 5px solid transparent;
}

.on-dashboard {
  color: #326297;
  border-bottom: 5px solid #326297;
}

.menu-header-home:hover,
.menu-header-home:focus {
  border-bottom: 5px solid #326297;
}

.log-out-card-alert {
  background: #ffffff;
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.06);
  padding: 16px 8px;
}

.log-out-card-alert:active {
  background: #f5bcbc;
}

.log-out-card-alert:focus-visible {
  background: #fce9e9;
}

.log-out-card-alert:hover {
  background: #fce9e9;
}

.contact-us-card {
  background: #ffffff;
  box-shadow: 0 12px 16px -4px #0000001a, 0 4px 6px -2px rgba(0, 0, 0, 0.06);
  padding: 16px 8px;
}

.contact-us-card:active {
  background: #d3e4ff;
}

.contact-us-card:focus-visible {
  background: #eaf1ff;
  z-index: 1000;
}

.contact-us-card:hover {
  background: #eaf1ff;
  z-index: 1000;
}

.contact-us:before {
  content: url(../../assets/icon-phone.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8px;
  vertical-align: middle;
}

.contact-us {
  text-decoration: none;
  color: #0f487c;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.contact-us:hover,
.contact-us:focus,
.contact-us:focus-visible {
  background-color: #eaf1ff;
}

.contact-us:active {
  background-color: #d3e4ff;
}

.log-out-alert:before {
  content: url(../../assets/icon-logout.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8px;
  vertical-align: middle;
}

.log-out-alert {
  text-decoration: none;
  color: #660000;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.log-out-alert:hover,
.log-out-alert:focus,
.log-out-card-alert:focus-visible {
  background-color: #fce9e9;
}

.log-out-alert:active {
  background-color: #f5bcbc;
}

.name-menu {
  border: none;
  background-color: #ffffff;
}

.name-menu:hover,
.name-menu:focus,
.name-menu:focus-visible {
  background-color: #eaf1ff;
}

.name-menu:active,
.name-menu-open {
  border: none;
  background-color: #d3e4ff;
}

.menu-right .amplify-flex.amplify-buttongroup.amplify-menu-content {
  border: none;
  display: flex;
  flex-direction: column;
}

.user-icon {
  margin: 0px;
  padding: 4px 0px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.user-name-and-business-name {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.menuName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  border-bottom: 1px solid #eff0f0;
}

.username {
  text-overflow: ellipsis;
  color: var(--amplify-colors-font-primary, #002749);
  font-size: 1rem;
  font-weight: 700;
  white-space: nowrap;
  text-align: right;
  line-height: 22px;
}

.menu-right {
  text-align: right;
}

.menu-right:hover {
  cursor: pointer;
}

@media (prefers-contrast: more) {
  .absLogo path {
    fill: currentColor;
  }

  .user-icon path {
    stroke: currentColor;
  }
}

@media (max-width: 767px) {
  .user-icon {
    flex-direction: column-reverse;
    width: 59px;
    height: 48px;
    gap: 0px;
    justify-content: center;
  }
}
