.number-wrapper {
  .tooltip-component {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

.report-number-information {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #4b4f54;
  margin-bottom: 4px;
}

.report-number-input {
  width: 328px;
  min-height: 46px;
}

.report-number-input label {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #10002e;
}

.report-number-input input::-webkit-outer-spin-button,
.report-number-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.report-number-input input[type='number'] {
  -moz-appearance: textfield;
}

.report-number-input input[type='text'] {
  max-width: 200px;
}
