@import '../../utils/stylesConstants.scss';

.open-menu-button {
  padding: 0;
  border: none;
}

.dashboard-nav-link {
  display: block;
  font-size: 16px;
  padding: 16px 16px 16px 20px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  color: #002749 !important;
  font-family: 'Open Sans-Regular', Helvetica;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
}

.active-dashboard-link {
  font-weight: 600;
  background: #FAFAFA;
  position: relative;
  ::before {
    content: " ";
    height: 100%;
    background-color: #326297;
    z-index: 1;
    width: 4px;
    position: absolute;
    left: 0;
  } 
}

.dashboard-nav-link:focus, .active-dashboard-link:focus {
  background: #EAF1FF;
  border-radius: 2px;
  outline: 3px solid var(--amplify-colors-outline, #9263DE);
  position: relative;
  z-index: 2;
  box-shadow: none;
}

.dashboard-nav-link:hover, .active-dashboard-link:hover {
  background: #EAF1FF;
  color: #00325B !important;
  box-shadow: none !important;
}

.dashboard-nav-link:active, .active-dashboard-link:active {
  background: #D3E4FF;
  box-shadow: none !important;
}

.dashboard-nav {
  margin-top: 48px;

  .dashboard-body {
    margin: 0;
    padding-bottom: 16px;
    width: 100%;

    > ul {
      margin: 0;
      padding: 0;

      > li {
        list-style-type: none;
        // Makes it work with iOS VoiceOver: zero-width space tricks it into using list semantics.
        content: '\200B';

        button {
          border-top: 0;
          border-right: 0;
          border-bottom: 0;
          width: 100%;
        }

        button:hover {
          box-shadow: none !important;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (prefers-contrast: more) {
  .side-nav-icon path {
    stroke: #000000;
  }
}

@media screen and (width < $medium) {
  .dashboard-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;

    position: fixed;
    width: 288px;
    max-width: 288px;
    left: -288px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    background: #ffffff;
    border-right: 1px solid #eff0f0;
    border-radius: 0px 16px 16px 0px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
    z-index: 1002;
    margin-top: 0;

    .nav-header {
      height: var(--top-nav-menu);

      /* Auto layout */
      display: flex;
      align-items: center;

      width: 288px;
      max-width: 767px;

      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;

      background: #ffffff;
      border-right: 1px solid #eff0f0;
      border-radius: 0px 16px 0px 0px;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px;
      gap: 4px;

      width: 288px;

      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      border-bottom: 4px solid #EFF0F0;
    }

    .nav-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0px;

      width: 288px;
      height: 378px;

      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    .dashboard-nav-link {
      padding-left: 24px;

      .amplify-flex {
        padding-left: 9px;
      }
    }

    .overlay {
      display: none;
    }
  }

  .hide-sidenav {
    display: none;
  }
}

@media screen and (min-width: $medium) {
  .nav-header {
    display: none;
  }

  .open-menu-button {
    display: none;
  }

  .overlay {
    display: none;
  }
}

.divider {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0px;
  position: relative;
}

.divider .divider-line {
  align-self: stretch;
  background-color: #eff0f0;
  border-radius: 1px;
  height: 1px;
  position: relative;
  width: 100%;
}

.overlay {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99;
}
