.container-grid {
  margin: 0;
  /* Gap is removed at this width. Adding padding to containers to center content */
  @media (max-width: 448px) {
    padding: 0px var(--amplify-space-large);
  }
}

.dashboard-container-right {
  margin: 0;
  /* Gap is removed at this width. Adding padding to containers to center content */
  @media (max-width: 448px) {
    padding-top: var(--amplify-space-large);
  }
}
