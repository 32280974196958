.business-select-menu-item-wrapper {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #1a1919;
  align-items: center;
}

.business-select-menu-item-wrapper img {
  max-height: 20px;
  margin: 0 auto;
}

.business-select-menu-item-divider {
  width: 1px;
  height: 18px;
  flex-grow: 0;
  background-color: #9a9ea4;
}
