.manage-connections-table {
  width: 100%;
  border-collapse: collapse;
  color: #1a1919;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 36px;
}

.manage-connections-table-thead {
  border-bottom: 1px solid #eff0f0;
  margin-bottom: 24px;
}

.manage-business-table-caption {
  text-align: left;
  width: 100%;
  margin: 0;
  padding-bottom: 24px;
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}

.manage-connections-table-heading {
  width: 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.manage-connections-table .amplify-label {
  text-align: left;
  color: #0d1926;
}

.amplify-searchfield {
  width: 320px;
}

.search-container {
  margin-top: 24px;
  float: right;
  font-size: 16px;
  color: #0d1926;
}

.amplify-searchfield .amplify-input {
  text-align: left;
  border-color: #5c6670;
  border-width: 2px;
}

.validation-error .amplify-input {
  border-color: #dc2626;
  box-shadow: 0px 0px 0px 1px #dc2626;
}

.input-error-message {
  font-size: 14px;
  text-align: left;
  line-height: 24px;
}

.manage-connections-table th {
  font-weight: 600;
}

.manage-connections-authorised-table-row {
  height: 80px;
  border-bottom: solid #eff0f0 1px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.manage-connections-authorised-table-row .amplify-button {
  font-weight: 600;
  display: flex;
  align-items: center;
}

.pagination-container {
  margin-top: 20px;
}

.pagination-container .amplify-button {
  border: none;
  border-radius: 8px;
}

.pagination-container .amplify-button:hover {
  border: 0.5px solid black;
}

.pagination-container .amplify-pagination__item-current {
  color: black;
  border: 0.5px solid #326297;
  border-radius: 8px;
  background-color: #f3f7fb;
  font-size: 16px;
  line-height: 1.19;
  font-weight: 600;
}

.amplify-pagination__item-current:focus-visible {
  box-shadow: 0 0 0 1px #326297, 0 0 0 4px #fff, 0 0 0 7px #9263de;
}

.manage-connections-table-options-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.manage-connections-table-options-row,
.manage-connections-table-options-header {
  text-align: right;
}

.manage-connections-table-business-header {
  padding-top: 48px;
  text-align: left;
}

.manage-connections-no-authorised {
  font-size: 16px;
  margin: 0 auto 0 auto;
  font-weight: 400;
  color: #5c6670;
  text-align: left;
  padding-top: 28px;
}

.manage-connections-menu {
  box-shadow: 0 4px 12px 0 #0d1a2640;
  border-color: transparent;
  border-width: 0px;
  border-radius: 4px;
}

manage-connections-menu:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

manage-connections-menu:last-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.manage-connections-menu > button {
  min-height: 48px;
  min-width: 224px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  align-items: center;
  justify-content: flex-start;
}

.manage-connections-menu-btn {
  padding: 14px 8px;
  border: none;
  display: flex;
  align-items: center;
}

.manage-connections-current-business-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #0d1926;
}
.manage-connections-dsp-name-heading {
  font-size: 14px;
  line-height: 16px;
  color: #71767d;
}

.dialog-heading {
  font-size: 19px;
  font-weight: 600;
}

.dialog-business-name {
  font-size: 16px;
  font-weight: 400;
}

.dialog-button-wrapper button {
  min-width: 144px;
  min-height: 46px;
  font-weight: 600;
}

.dialog-text {
  margin-top: 0;
  max-width: 457px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.disconnect-business-menu-item {
  color: var(--amplify-colors-font-error, #660000);
}

.disconnect-business-menu-item:active {
  background-color: #f5bcbc !important;
  color: #660000 !important;
}

.disconnect-business-menu-item:focus {
  background-color: #fce9e9 !important;
  color: #660000 !important;
}

.disconnect-business-menu-item:hover {
  background-color: #fce9e9 !important;
  color: #660000 !important;
}

.switch-business-menu-item {
  color: #0f487c;
  display: none !important;
}

.switch-business-menu-item:active {
  background-color: #d3e4ff !important;
  color: #00325b !important;
}

.switch-business-menu-item:hover {
  background-color: #eaf1ff !important;
  color: #00325b !important;
}

.switch-business-menu-item:focus {
  background-color: #eaf1ff !important;
  color: #00325b !important;
}

.switch-business-item {
  color: #0f487c;
  font-weight: 600;
}

.switch-button-large-device:active,
.manage-connections-menu-btn:active {
  background-color: #d3e4ff !important;
}

.switch-button-large-device:hover,
.manage-connections-menu-btn:hover {
  background-color: #eaf1ff !important;
}

.switch-button-large-device:focus,
.manage-connections-menu-btn:focus {
  background-color: #eaf1ff !important;
}

.manage-connections-menu-btn:active,
.manage-connections-menu-btn[data-state='open'] {
  background-color: #d3e4ff !important;
}

.amplify-field-group__inner-end button {
  border: none;
  border-radius: 8px;
}

.amplify-field-group__inner-end button:hover {
  background-color: #a6d8ff;
}

.add-business-button-manage-connections {
  min-width: 163px;
  min-height: 48px;
  color: #0f487c;
  background-color: transparent;
  border: 2px solid #326297;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(13, 26, 38, 0.05);
}

.add-business-button-manage-connections:hover {
  border: 2px solid #326297;
  border-radius: 8px;
  background: #eaf1ff;
}

.add-business-button-manage-connections:active {
  border: 2px solid #326297;
  border-radius: 8px;
  background: #d3e4ff;
}

@media screen and (max-width: 767px) {
  .manage-business-table-caption {
    font-size: 28px;
  }

  .dialog-button-wrapper button {
    min-width: 134px;
  }

  .search-container {
    width: 100%;
  }

  .switch-button-large-device {
    display: none !important;
  }
  .switch-business-menu-item {
    display: flex !important;
  }
}
