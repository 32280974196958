.breadcrumb div {
  display: flex;
  color: #0F487C;
  border: none;
}

.breadcrumb div:before {
  content: url(../../assets/icon-breadcrumb-chevron.svg); 
  display: block;
  margin-right: 10px;
  height: 24px;
  width: 24px;
  border: none;
}  

.breadcrumb {
  display: inline-block;
  padding: 0 12px 0 0;
  text-decoration: none;
  border: none;
  font-weight: 600;
}