@import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

:root {
  --top-nav-menu: 76px;
  --amplify-fonts-default-static: "Open Sans", sans-serif;
  --amplify-fonts-default-variable: "Open Sans", sans-serif;
  --color-error-600: #dc2626;
  --amplify-components-button-primary-hover-background-color: #1f3d5e;
}

body {
  margin: 0 !important;
  min-height: calc(100vh - var(--top-nav-menu));
}

.main-wrapper {
  margin-top: 0px;
}

.heading--3 {
  font-size: 28px;
}

.amplify-button--primary {
  /* this rule is far to general the below class is to correct the buttons broken */
  /*TODO move the 3px radius to the appropriate class and change back to 8px */
  border-radius: 3px;
  font-weight: normal;
}

.primary-button-radii-override {
  border-radius: 8px !important;
}

.amplify-button:focus-visible,
.amplify-button--primary:focus-visible,
.amplify-button--link:focus-visible {
  box-shadow: 0 0 0 4px #fff, 0 0 0 7px rgba(146, 99, 222, 1);
  border-radius: 3px;
}

.amplify-button:hover,
.amplify-button--primary:hover,
.amplify-button--link:hover {
  box-shadow: none;
}

.amplify-button--link {
  font-size: 16px;
  line-height: 1.19;
  font-weight: 600;
}

.input-default {
  margin: 8px 0;
  border: 1px solid #ebeced;
  border-radius: 8px;
  padding: 12px;
}

.input-default:focus {
  border: 2px solid #2c5684;
}

.input-default:hover {
  background-color: #f3f7fb;
}

.input-success {
  margin: 8px 0;
  border: 2px solid #059669;
  padding: 12px;
}

.input-success-message {
  color: #059669;
  margin: 0;
  padding: 0;
}

.input-success-message::before {
  content: url(./assets/icon-success.svg);
  margin-right: 5px;
}

.input-error {
  margin: 8px 0;
  border: 2px solid var(--color-error-600);
  padding: 12px;
}

.input-error-message {
  color: var(--color-error-600);
  margin: 0;
  padding: 0;
}

.input-error-message::before,
.amplify-field__error-message::before {
  content: url(./assets/icon-alert.svg);
  margin-right: 5px;
}

.input-warning {
  margin: 8px 0;
  padding: 12px;
  border: 2px solid #d97706;
}

.input-warning-message {
  color: #d97706;
  margin: 0;
  padding: 0;
}

.input-warning-message::before {
  content: url(./assets/icon-warning.svg);
  margin-right: 5px;
}

.amplify-switch-thumb {
  background-color: #ffffff;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
}

.amplify-switch-track--focused {
  border: 3px white;
  box-shadow: 0 0 0 4px #fff, 0 0 0 7px #9263de;
}

.button-action:before {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.amplify-switch-track {
  cursor: pointer;
}

.spinning-loader-container {
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--top-nav-menu));
}

.spinning-loader {
  width: 100px;
  height: 100px;
}

.report-button-inline-error {
  text-align: right;
  margin-right: 102px;
}

.amplify-link {
  text-decoration: underline;
  color: #326297;
}

.amplify-link:hover {
  text-decoration: underline;
  color: #000000;
  background: #a6d8ff;
  box-shadow: 0 0 0 5px #a6d8ff;
}

.amplify-link:focus {
  text-decoration: underline;
  box-shadow: 0 0 0 2px #fff, 0 0 0 5px #9263de;
  outline: 1px solid transparent;
}

a[target="_blank"]:after {
  content: url(assets/icon-new-tab.svg);
  vertical-align: super;
  font-size: 8px;
  padding-left: 3px;
}

.amplify-field__error-message {
  color: var(--color-error-600);
  font-size: 16px;
}

.amplify-input--error {
  border: 2px solid var(--color-error-600);
}

.amplify-input--error:focus {
  border-color: var(--color-error-600);
}

.amplify-input,
.amplify-textarea {
  border-radius: 8px;
}

.amplify-button:focus {
  outline: 2px solid transparent;
}

.amplify-grid {
  gap: var(--amplify-space-xl);
  /* Remove the gap at this width to address issues caused by the gap */
}

@media (max-width: 767px) {
  .amplify-grid {
    gap: var(--amplify-space-small);
  }
}

@media (max-width: 448px) {
  .amplify-grid {
    gap: 0px;
  }
}

.skeleton {
  background-color: #eff0f0;
  background-image: linear-gradient(
    90deg,
    rgba(256, 256, 256, 0),
    rgba(256, 256, 256, 0.5),
    rgba(256, 256, 256, 0)
  );
  background-size: 100px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: skeleton-loading 1s ease infinite;
}

@keyframes skeleton-loading {
  to {
    background-position: right -40px top 0;
  }
}
